<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div style="margin-bottom: 20px;">
				<a-radio-group v-model="queryParam.region" @change="getReginTab" size="large">
					<a-radio-button :value="item.id" v-for="(item,index) in regsonList">{{item.deptName}}</a-radio-button>
				</a-radio-group>
			</div>
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="5" :sm="24">
							<a-form-item label="客户名称" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="5" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入手机号" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="5" :sm="24">
							<a-form-item label="工作单位" prop="work">
								<a-input v-model="queryParam.work" placeholder="请输入工作单位" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="5" :sm="24">
							<a-form-item label="省份" prop="province">
								<a-input v-model="queryParam.province" placeholder="请输入省份" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="4" :sm="24">
							<a-form-item label="市区" prop="area">
								<a-input v-model="queryParam.area" placeholder="请输入市区" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="5" :sm="24">
							<a-form-item label="创建时间" prop="area">
								<a-date-picker style="width: 100%;" v-model="queryParam.createTime" @change="getTimes" />
								<!-- <a-input v-model="queryParam.createBeginTime" placeholder="请输入地区" allow-clear /> -->
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 8 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
								<!-- <a @click="toggleAdvanced" style="margin-left: 8px">
									{{ advanced ? '收起' : '展开' }}
									<a-icon :type="advanced ? 'up' : 'down'" />
								</a> -->
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations" style="display: flex;justify-content: space-between;align-items: center;">
				<div>
					<a-button type="primary" @click="$refs.createForm.handleAdd(queryParam.region)"
						v-hasPermi="['roster:roster:add']">
						新增新名单客户
					</a-button>
					<a-divider type="vertical" />
					<a-button type="primary" @click="handleReceive" v-hasPermi="['roster:roster:receive']">
						领取客户
					</a-button>
					<a-divider type="vertical" />
					<a-upload name="file" listType="text" :beforeUpload="beforeUpload" :customRequest="customRequest"
						:remove="removeImg">
						<a-button v-hasPermi="['roster:roster:export']">
							导入新名单客户文件
						</a-button>
					</a-upload>
					<a-divider type="vertical" />
					<a-button type="danger" :disabled="multiple" @click="handleDelete(selectedRowKeys)"
						v-hasPermi="['roster:roster:receive']">
						批量删除
					</a-button>
					<a-divider type="vertical" />
					<a-button type="danger" @click="handleDeleteAll" v-hasPermi="['roster:roster:receiveAll']">
						一键删除
					</a-button>
				</div>
				<div class="num-statistics"
					style="display: flex;justify-content: space-between;align-items: center;width: 40%;">
					<div>坐席当前领取总数：<span class="red">{{quantityClaimed.allReceiveNum}}</span></div>
					<div>今日可领取数量：<span class="red">{{quantityClaimed.num}}</span></div>
					<div>今日已领取数：<span class="green">{{quantityClaimed.receivedNum}}</span></div>
				</div>

				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 数据展示 -->
			<a-table v-if="role!='xs' || role!='kf'" :loading="loading" :size="tableSize" rowKey="id" :columns="columns"
				:data-source="list" :pagination="false"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :bordered="tableBordered">
				<span slot="lastCallTime" slot-scope="text, record">
					{{ parseTime(record.lastCallTime) }}
				</span>
				<span slot="followUpTime" slot-scope="text, record">
					{{ parseTime(record.followUpTime) }}
				</span>
				<span slot="operation" slot-scope="text, record">
					<!-- v-hasPermi="['roster:roster:edit']" -->
					<a @click="$refs.createForm.handleUpdate(record, undefined)">编辑</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination v-if="role!='xs' || role!='kf'" class="ant-table-pagination" show-size-changer show-quick-jumper
				:current="queryParam.pageNum" :total="total" :page-size="queryParam.pageSize"
				:showTotal="total => `共 ${total} 条`" @showSizeChange="onShowSizeChange" @change="changeSize"
				:pageSizeOptions="pageSizeOptions" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageRoster,
		listRoster,
		delRoster,
		receiveRoster,
		getRegionLists,
		getTodayNum,
		signature,
		exportsFile,
		roterDelete,
		deleteAll
	} from '@/api/roster/roster'
	import CreateForm from './modules/CreateForm'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Roster',
		components: {
			CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: null,
					lastCallTime: null,
					createTime: null,
					clue: null,
					pageNum: 1,
					pageSize: 10,
				},
				regsonList: [],
				columns: [{
						title: '序号',
						dataIndex: 'id',
						width: 60,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '客户名称',
						dataIndex: 'name',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '手机号',
						dataIndex: 'phone',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '工作单位',
						dataIndex: 'work',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '市区',
						dataIndex: 'area',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '省份',
						dataIndex: 'province',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '备注',
						dataIndex: 'notes',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '创建人',
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '创建时间',
						dataIndex: 'createTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: '18%',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				quantityClaimed: {},
				ossUploadUrl: 'http://mypkgz.natappfree.cc',
				uploadData: {
					policy: '',
					key: '',
					OSSAccessKeyId: '',
					success_action_status: 200,
					signature: '',
				},
				role: '',
				pageSizeOptions: ['10', '50', '80', '100', '200', '400', '600']
			}
		},
		filters: {},
		created() {
			this.getRegionList()
			this.getTodayNums()
			this.role = localStorage.getItem('roleCodes').split(',')[0];
			console.log('客户角色', this.role)
			// this.getSignature()
		},
		computed: {},
		watch: {},
		methods: {
			// 切换区域
			getReginTab(e) {
				this.getList()
			},
			// 今日可领取数量
			getTodayNums() {
				getTodayNum().then(res => {
					console.log('今日可领取数量', res)
					if (res.success) {
						this.quantityClaimed = res.data;
					}
				})
			},
			// 区域列表
			getRegionList() {
				getRegionLists().then(res => {
					console.log('区域列表', res);
					if (res.success) {
						this.regsonList = res.data
						if (this.regsonList.length == 0) {
							this.$message.error('该用户暂未分配区域')
							return false
						}
						this.queryParam.region = this.regsonList[0].id;
						this.getList()
					}
				})
			},
			getTimes(e) {
				console.log(e.format('YYYY-MM-DD'))
				this.queryParam.createTime = e.format('YYYY-MM-DD hh:mm:ss');
			},
			/** 查询新名单库列表 */
			getList() {
				this.loading = true
				pageRoster(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					name: null,
					phone: null,
					work: null,
					area: null,
					notes: null,
					region: this.queryParam.region,
					lastCallTime: null,
					createTime: null,
					clue: null,
					pageNum: 1,
					pageSize: 10,
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			// oss签名
			getSignature() {
				signature().then(res => {
					console.log('oss', res);
					let data = res.data;
					// this.uploadData.key = data
					this.ossUploadUrl = data.resultUrl;
					this.uploadData.policy = data.policy;
					this.uploadData.OSSAccessKeyId = data.accessid;
					this.uploadData.signature = data.signature;
				})
			},
			beforeUpload(file) {
				// const isCsv = file.type === 'text/csv';
				// if (!isCsv) {
				// 	this.$message.error('只能上传CSV文件！');
				// }
				const isLt10M = file.size / 1024 / 1024 < 10;
				if (!isLt10M) {
					this.$message.error('文件大小不能超过10MB！');
				}
				// return isCsv && isLt10M;
				return isLt10M;
			},
			customRequest(options) {
				console.log(options.file)
				const formData = new FormData();
				formData.append('file', options.file);
				formData.append('region', this.queryParam.region);
				exportsFile(formData).then(res => {
					console.log('导入数据', res);
					if (res.code == "20001") {
						this.$message.success('导入成功');
						this.getList()
					} else {
						this.$message.error('导入失败');
					}

				})
			},
			removeImg(info) {
				// console.log('删除文件', info);
				this.fileList.map((item, index) => {
					if (info.name == item.name) {
						this.fileList.splice(index, 1);
					}
				})
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return roterDelete(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			// 一键删除所有
			handleDeleteAll() {
				var that = this
				let id = this.queryParam.region
				this.$confirm({
					title: '提示',
					content: '是否删除当前区域下所有数据',
					onOk() {
						return deleteAll({
								id: id
							})
							.then(() => {
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('roster/roster/export', {
							...that.queryParam
						}, `新名单库_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			},
			/** 领取按钮操作 */
			handleReceive() {
				var that = this
				this.$confirm({
					title: '是否确认领取?',
					//content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						return receiveRoster()
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.getTodayNums()
								that.$message.success(
									'领取成功',
									3
								)
							})
					},
					onCancel() {}
				})
			}
		}
	}
</script>

<style>
	.ant-upload-list {
		display: none;
	}

	.num-statistics {}

	.num-statistics div {
		font-size: 15px;
		font-weight: 500;
	}

	.red {
		color: red;
	}

	.green {
		color: green;
	}
</style>